<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		.card.card-custom.gutter-b
			.card-header
				.card-title
					.card-label Publisher Postbacks
				.card-toolbar
			.card-body
				form.form.form-inline.form-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
					a.btn.btn-primary.toggle-filters.d-md-none(v-b-toggle.form-filters)
						span Filters
						i.fa.fw.fa-chevron-up.transition-all(:class="{'rotate-180':filtersExpanded}")
					b-collapse#form-filters.dont-collapse-sm(style="width: 100%;", v-model="filtersExpanded")
						.row.filters-form-row
							.form-row-main.col-auto.mr-auto(style="flex:1;")
								.form-group
									label Search
									input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword")
								dw-date-time-picker(v-model="filters.datePicker")
								//.form-group
									label Search
									input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword", v-debounce:300ms="loadData")
								.form-group
									label Status
									select.form-control(v-model="filters.status")
										option(v-for="o in statusOptions", :value="o.v") {{ o.t }}
								.form-group
									label Type
									select.form-control(v-model="filters.type")
										option(v-for="o in typeOptions", :value="o.v") {{ o.t }}
								select-publisher(v-model="filters.publisher_id")
								.form-group.no-label
									button.btn.btn-primary(type="submit") Go

				p.clearfix &nbsp;

				paginate(:paginator="paginate", @update-page-size="loadData")

				.table-wrapper
					table.table.table-bordered
						thead
							tr
								th ID
								th Type
								th Publisher
								th Advertiser
								th Offer
								th Placement
								th Event
								th ClickID
								th URL
								th Response
								th Created At
								th Actions
						tbody
							tr(v-if="records.length===0")
								td(colspan="11") No matching records were found
							tr(v-for="r in records", :key="r.id")
								td {{ r.id }}
								td {{ r.type }}
								td
									entity(v-if="r.publisher", :id="r.publisher.id", :name="r.publisher.name", type="publisher")
								td
									entity(v-if="r.advertiser", :id="r.advertiser.id", :name="r.advertiser.name", type="advertiser")
								td
									entity(v-if="r.offer", :id="r.offer.id", :name="r.offer.name", type="offer")
								td
									entity(v-if="r.placement", :id="r.placement.id", :name="r.placement.name", type="placement")
								td
									entity(v-if="r.event", :id="r.event.id", :name="r.event.name", type="event")
								td
									router-link(:to="{name:'click-view', params:{id:r.click_id}}") {{ r.click_id }}
									span.text-muted(v-if="r.click") <br />{{ r.click.created_at }}
								td
									span.req-preview {{ r.url }}
								td
									span.req-preview {{ r.http_response }}
								td {{ r.created_at }}
								td.actions
									//router-link.btn.btn-action.btn-secondary(:to="{name:'postback-log-view', params: {id:r.id}}")
										i.la.la-pen
									//.btn.btn-action.btn-outline-danger()
										i.la.la-trash

				paginate(:paginator="paginate", @update-page-size="loadData")

</template>
<style lang="scss">
pre {
	background: #f0f0f0;
	padding: 5px;
	border-radius: 3px;
	font-size: 8px;
}
.req-preview {
	font-size: 10px;
	display: inline-block;
	width: 200px;
	word-break: break-all;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from "moment";
export default {
	name: 'PublisherPostbacks',
	data(){
		return {
			busy: false,
			// PERMS: null,
			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 50,
				onPageChange: () => {
					this.loadData();
				}
			},
			filters: {
				keyword: '',
				type: '',
				status: undefined,
				publisher_id: [],
				datePicker: {
					timezone: 0,
					dateRange: {
						startDate: null,
						endDate: null
					},
				}
			},
			typeOptions: [
				{v: '', t: 'All'},
				{v: 'install', t: 'install'},
				{v: 'event', t: 'event'},
				{v: 'rejection', t: 'rejection'},
			],
			statusOptions: [
				{v: undefined, t: 'All'},
				{v: 0, t: 'New'},
				{v: 1, t: 'Success'},
				{v: 2, t: 'Retry'},
				{v: 3, t: 'Ignore'},
				{v: 4, t: 'Error'},
				{v: 5, t: 'Privacy'},
			],
			records: [],
			filtersExpanded: false,
		};
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ title: 'Media' },
			{ title: 'Reports' },
			{ title: 'Publisher Postbacks' }
		]);
		setTimeout(() => {
			this.loadData();
		});
	},
	methods: {
		async loadData(){
			this.busy = true;
			let params = {
				page: this.paginate.page,
				limit: this.paginate.limit,
				keyword: this.filters.keyword,
				type: this.filters.type,
				status: this.filters.status,
				publisher_id: this.filters.publisher_id.map(r => r.v).join(','),
				date_start: moment(this.filters.datePicker.dateRange.startDate).format('YYYY-MM-DD HH:mm'),
				date_end: moment(this.filters.datePicker.dateRange.endDate).format('YYYY-MM-DD HH:mm'),
			};
			try {
				let resp = await this.$api.get('/publisher-postbacks/getList', {params});
				this.records = resp.records;
				this.paginate.total = resp.total;
				this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
			} catch (e){
				console.error(e);
			}
			this.busy = false;
		}
	}

}
</script>
